import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { combineLatest, EMPTY, filter, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';

import {
    loadCropsSuccess,
    loadExpandedCropsIfNeeded,
    selectCrops,
    selectCurrentCrops,
} from '@priva/masterdata';

import { loadKPIsIfNeeded } from '@app/crops';
import { loadDefinitionsIfNeeded } from '@app/definitions';
import { selectCropKpis } from '@app/monitoring';

import {
    loadCropsAndDefinitionsIfNeeded,
    loadCropsDataIfTriggered,
    navigateToUrl,
    resetTriggeredState,
    validateActiveCrops,
    validateAndProcessRouteData,
    validateCrop,
} from './crops-page.actions';
import { selectCropsLoaded, selectIsLoadCropsAndDefinitionsTriggered } from './crops-page.selectors';

export enum urls {
    parent = '/',
    crops = '/crops',
    dashboard = '/crops/dashboard',
    manage = '/crops/manage',
    anomalies = '/crops/anomalies',
}

@Injectable({
    providedIn: 'root',
})
export class CropsPageEffects {
    private readonly _actions$ = inject(Actions);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);

    /**
     * This effect listens to ROUTER_NAVIGATED for /crops url.
     * If the url is /crops we navigate to the dashboard.
     * If the url is /crops/? we trigger the routeChanged action.
     */
    public routerNavigated$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) => !!action.payload.routerState.url.startsWith(urls.crops),
            ),
            switchMap((action) => {
                if (action.payload.routerState.url === urls.crops) {
                    return of(navigateToUrl({ url: urls.dashboard }));
                } else {
                    return of(loadCropsAndDefinitionsIfNeeded());
                }
            }),
        );
    });

    /**
     * This effect just asks if the crops are available in store
     * If they are not available it will trigger the loadCropsAndDefinitionsIfNeeded action.
     * If they are available it will trigger the loadCropsDataIfTriggered action.
     */
    public loadCropsAndDefinitionsIfNeeded$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsAndDefinitionsIfNeeded),
            withLatestFrom(this._store.select(selectCropsLoaded)),
            mergeMap(([_action, cropsLoaded]) => {
                if (cropsLoaded) {
                    return of(loadCropsDataIfTriggered());
                } else {
                    return of(loadExpandedCropsIfNeeded(), loadDefinitionsIfNeeded());
                }
            }),
        );
    });

    /**
     * This effect listens for crops are available.
     * Then it gets the crops from the store and the cropId from the url(if available).
     * And then triggers the validation process
     */
    public loadCropsDataIfTriggered$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsDataIfTriggered),
            withLatestFrom(
                this._store.select(selectCrops),
                this._store.select(selectIsLoadCropsAndDefinitionsTriggered),
            ),
            switchMap(([_action, crops, isTriggered]) => {
                const cropId = this.extractCropId(this._router.url);
                if (!isTriggered) return EMPTY; // Do nothing if not triggered
                return of(validateAndProcessRouteData({ cropId: cropId, crops: crops }));
            }),
        );
    });

    /**
     * This effect listens for the loadCropSuccess fired from masterdata.
     * If the triggered state is true, then it gets the crops from the action and the cropId from the url(if available).
     * And then dispatch the validation process
     */
    public listenForLoadCropsSuccessValidateIfTriggered$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(loadCropsSuccess),
            withLatestFrom(this._store.select(selectIsLoadCropsAndDefinitionsTriggered)),
            switchMap(([action, isTriggered]) => {
                const cropId = this.extractCropId(this._router.url);
                if (!isTriggered) return EMPTY; // Do nothing if not triggered and loaded from /crops
                return of(validateAndProcessRouteData({ cropId: cropId, crops: action.crops }));
            }),
        );
    });

    /**
     * This effect take the props from the validateAndProcessRouteData action and decides which validation route to trigger
     */
    public validateAndProcessRouteData$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(validateAndProcessRouteData),
            map((action) => {
                const cropId = action?.cropId;
                if (cropId) {
                    return validateCrop({ cropId, crops: action.crops });
                } else {
                    return validateActiveCrops({ crops: action.crops });
                }
            }),
        );
    });

    /**
     * This effect will check if there are current crops.
     * If current crops exist navigation will continue to the dashboard, if not it will navigate to the manage page.
     */
    public validateActiveCrops$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(validateActiveCrops),
            withLatestFrom(this._store.select(selectCurrentCrops)),
            switchMap(([_action, currentCrops]) => {
                return combineLatest([
                    of(currentCrops),
                    this._store.select(selectCropKpis).pipe(filter((kpiConfigs) => !!kpiConfigs)),
                ]);
            }),
            mergeMap(([currentCrops, kpiConfigs]) => {
                if (!currentCrops || currentCrops.length === 0) {
                    return [navigateToUrl({ url: urls.manage }), resetTriggeredState()];
                }
                const cropIds = currentCrops.map(({ id }) => id);
                const kpiIds = kpiConfigs.map(({ metricId }) => metricId);

                return [
                    loadKPIsIfNeeded({
                        cropIds,
                        kpiIds,
                        dateRequest: {
                            date: dayjs().subtract(1, 'day'),
                            dateRange: 'daily',
                        },
                    }),
                    resetTriggeredState(),
                ];
            }),
        );
    });

    /**
     * This effect will check if the cropId matches any crop in the store.
     * If it does not exist it will navigate to '/'.
     */
    public validateCrop$ = createEffect(() => {
        return this._actions$.pipe(
            ofType(validateCrop),
            filter((action) => !!action.cropId && !!action.crops),
            mergeMap((action) => {
                const { cropId, crops } = action;
                const crop = crops.find((crop) => crop.id === cropId);
                if (!crop) {
                    return of(navigateToUrl({ url: urls.parent }));
                } else {
                    return of(resetTriggeredState());
                }
            }),
        );
    });

    /**
     * This effect will navigate to the url provided.
     */
    public navigateToParent$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(navigateToUrl),
                tap((action) => {
                    this._router.navigate([action.url]);
                }),
            ),
        { dispatch: false },
    );

    private extractCropId(url: string): string {
        const segments = url.split('/');
        const idIndex = segments.findIndex((segment) => segment === 'dashboard');
        if (idIndex !== -1 && segments[idIndex + 1]) {
            return segments[idIndex + 1];
        }
        return '';
    }
}
