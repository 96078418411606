import { createSelector } from '@ngrx/store';

import { Characteristics } from '@app/characteristics';

import { CropDevelopmentState, CropDevelopmentStateContainer } from './crop-development-state.model';

export const selectCropDevelopmentState = (state: CropDevelopmentStateContainer) => state.cropDevelopment;

export const selectCharacteristics = createSelector(
    selectCropDevelopmentState,
    (state: CropDevelopmentState) => {
        return state.characteristicDefinitions;
    },
);

export const selectCharacteristicsIds = createSelector(selectCharacteristics, (characteristics) => {
    return characteristics ? characteristics.map((characteristic) => characteristic.id) : undefined;
});

export const selectCropCharacteristics = createSelector(selectCharacteristicsIds, (characteristics) => {
    if (!characteristics) {
        return undefined;
    }

    return Characteristics.filter((config) => characteristics.includes(config.characteristicsId));
});
